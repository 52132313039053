import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
// tslint:disable-next-line:max-line-length
import {
    BomItem,
    ConfigitConfiguration,
    ConfigitConfigurationAndProducts,
} from '../components/questionnaire/configit-quest-adapter/configit-types.interface';
// tslint:disable-next-line:max-line-length
import { PackerConfigitQuestAdapterService } from '../components/questionnaire/configit-quest-adapter/packer-configit-quest-adapter.service';
import { Quote, QuoteAssignment, QuoteLine, QuoteService } from './quote.service';
import { EnvironmentDiffService } from './environment-diff.service';
import { environment } from '../../../environments/environment';
import { ProductsCategoryService } from './products-category.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationResultService {
    public configuration$: Observable<ConfigitConfigurationAndProducts | undefined>;

    constructor(
        private quoteService: QuoteService,
        private quests: PackerConfigitQuestAdapterService,
        private environmentDiffService: EnvironmentDiffService,
        private productCategoryService: ProductsCategoryService
    ) {
        this.configuration$ = this.quoteService.getCurrentQuoteObservable().pipe(
            debounceTime(2500),
            switchMap((quote: Quote | undefined) => {
                if (quote) {
                    return this.getConfigurationForCurrentQuote(quote);
                }
                return of(undefined);
            }),
            shareReplay(1)
        );
    }

    private getConfigurationForCurrentQuote(quote: Quote): Observable<ConfigitConfigurationAndProducts> {
        const primaryLine = this.quoteService.getPrimaryLine(quote.quote);
        const secondaryLine = this.quoteService.getSecondaryLine(quote.quote);
        const tertiaryLine = this.quoteService.getTertiaryLine(quote.quote);
        const line = tertiaryLine || secondaryLine || primaryLine;
        if (secondaryLine && tertiaryLine) {
            // for hybrid model we need to merge the bomItems and schemePdfs from both configurations, but we can use tertiary configuration
            return forkJoin([
                this.getConfigurationForQuoteLine(secondaryLine),
                this.getConfigurationForQuoteLine(tertiaryLine),
            ]).pipe(
                tap(([secondary, tertiary]) => {
                    // merge schemePdfs from both models
                    const secondarySchemes = this.environmentDiffService.getCurrentSchemes(
                        secondary.configuration.environmentDiff
                    );
                    const tertiarySchemes = this.environmentDiffService.getCurrentSchemes(
                        tertiary.configuration.environmentDiff
                    );
                    this.quoteService.updateSchemePdfs({
                        primary: [...(secondarySchemes?.primary || []), ...(tertiarySchemes?.primary || [])].filter(
                            Boolean
                        ),
                        secondary: [
                            ...(secondarySchemes?.secondary || []),
                            ...(tertiarySchemes?.secondary || []),
                        ].filter(Boolean),
                    });
                    // add or remove CATEGORY-BDIS in products cache
                    this.productCategoryService.updateProductCategoryDiscountsInCache(
                        secondary.configuration.environmentDiff,
                        tertiary.configuration.environmentDiff
                    );

                    // update HYBRID-PACKAGE environment variable
                    this.environmentDiffService.updateHybridPackageInCache(tertiary.configuration.environmentDiff);
                }),
                map(([secondary, tertiary]) => {
                    return {
                        configuration: tertiary.configuration,
                        bomItems: this.uniqueBomItems([...secondary.bomItems, ...tertiary.bomItems]),
                    };
                })
            );
        }
        return this.getConfigurationForQuoteLine(line).pipe(
            tap((model) => {
                const currentSchemes = this.environmentDiffService.getCurrentSchemes(
                    model.configuration.environmentDiff
                );
                this.quoteService.updateSchemePdfs(currentSchemes);
                // add or remove CATEGORY-BDIS in products cache
                this.productCategoryService.updateProductCategoryDiscountsInCache(model.configuration.environmentDiff);
            }),
            map((model) => ({
                ...model,
                bomItems: this.uniqueBomItems(model.bomItems),
            }))
        );
    }

    private uniqueBomItems(bomItems: BomItem[]) {
        // merge bom items with same material number (merge quantity)
        return bomItems.reduce((items: BomItem[], curr: BomItem) => {
            const duplicate = items.find((i) => i.material === curr.material);
            if (duplicate) {
                duplicate.componentQuantity += curr.componentQuantity;
                return items;
            }
            return [...items, curr];
        }, []);
    }

    public getConfigurationForQuoteLine(
        line: QuoteLine,
        checkSupport = false
    ): Observable<ConfigitConfigurationAndProducts> {
        const material = line?.variantCode || environment.quest.project;
        if (line) {
            const assignments = line.assignments
                .map((a) => {
                    if (a.isUserAssignment === undefined) {
                        a.isUserAssignment = !a.isDefault;
                    }
                    return a;
                })
                .filter((a) => a.isUserAssignment);
            return this.quests.getConfiguration(material, assignments).pipe(
                take(1),
                map(({ configuration, bomItems }) => {
                    if (checkSupport) {
                        configuration.unsupported = this.isConfigurationUnsupported(configuration, assignments);
                    }

                    return { configuration, bomItems };
                })
            );
        } else {
            return of({ configuration: {}, bomItems: [] });
        }
    }
    private isConfigurationUnsupported(configuration: ConfigitConfiguration, assignments: QuoteAssignment[]) {
        return (
            !configuration.valid ||
            assignments.some(
                (assignment) =>
                    !configuration.newAssignments.find(
                        (a) => a.variableName === assignment.variableName && a.valueName === assignment.valueName
                    )
            )
        );
    }
}
