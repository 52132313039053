import { Injectable } from '@angular/core';

export interface SchemePdf {
    primary: string[];
    secondary: string[];
}

export interface EnvironmentDiff {
    added: {
        'SCHEME-PDF'?: string;
        'SCHEME-PDF-PRIMARY'?: string;
        'SCHEME-PDF-SECONDARY'?: string;
    };
    removed: {};
    modified: {
        'SCHEME-PDF'?: EnvironmentDiffModified;
        'SCHEME-PDF-PRIMARY'?: EnvironmentDiffModified;
        'SCHEME-PDF-SECONDARY'?: EnvironmentDiffModified;
    };
}

export interface EnvironmentDiffModified {
    removed?: string[];
    added?: { value: string }[];
}

interface EnvironmentDiffCache {
    hybridPackage: string;
}

@Injectable({
    providedIn: 'root',
})
export class EnvironmentDiffService {
    private cache: EnvironmentDiffCache = {
        hybridPackage: '',
    };
    constructor() {}

    public clearCache() {
        this.cache = { hybridPackage: '' };
    }

    public getCurrentSchemes(environmentDiff: EnvironmentDiff): SchemePdf {
        if (environmentDiff?.added['SCHEME-PDF-PRIMARY'] || environmentDiff?.added['SCHEME-PDF-SECONDARY']) {
            const primary = (environmentDiff.added['SCHEME-PDF-PRIMARY'] || '').split(';');
            const secondary = (environmentDiff.added['SCHEME-PDF-SECONDARY'] || '').split(';');
            return { primary, secondary };
        }
        if (environmentDiff?.modified['SCHEME-PDF-PRIMARY'] || environmentDiff?.modified['SCHEME-PDF-SECONDARY']) {
            const primary = this.getModified(environmentDiff.modified['SCHEME-PDF-PRIMARY']);
            const secondary = this.getModified(environmentDiff.modified['SCHEME-PDF-SECONDARY']);
            return { primary, secondary };
        }
        return undefined;
    }

    public updateHybridPackageInCache(environmentDiff: EnvironmentDiff) {
        this.cache.hybridPackage = this.getHybridPackage(environmentDiff);
    }

    public getHybridPackageFromCache() {
        return this.cache.hybridPackage;
    }

    private getHybridPackage(environmentDiff: EnvironmentDiff) {
        if (environmentDiff?.added['HYBRID-PACKAGE']) {
            return environmentDiff.added['HYBRID-PACKAGE'];
        }
        if (environmentDiff?.modified['HYBRID-PACKAGE']) {
            return this.getModified(environmentDiff.modified['HYBRID-PACKAGE']);
        }
    }

    private getModified(modified: EnvironmentDiffModified) {
        if (modified?.added?.length) {
            return modified.added.map((added) => added.value.split(';')).reduce((prev, curr) => prev.concat(curr), []);
        }
        if (modified?.removed?.length) {
            return [];
        }
        return undefined;
    }
}
