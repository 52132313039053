import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, map, mapTo, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';
import { SchemePdf } from './environment-diff.service';
import { QuotePermissionService } from './quote-permission.service';
import { PlanningType } from './projects.service';
import { BomItem } from '../components/questionnaire/configit-quest-adapter/configit-types.interface';

export interface QuoteDiscount {
    materialGroup: string;
    partnerDiscount: string; // this is in fact the quoteDiscount NOT the partner discount
}

@Injectable({
    providedIn: 'root',
})
export class QuoteService {
    private quote$: BehaviorSubject<Quote | undefined> = new BehaviorSubject(undefined);
    public quoteErrors$: Subject<any> = new Subject<any>();

    constructor(private httpService: HttpClient, private quoteValidation: QuotePermissionService) {
        this.quote$
            .pipe(
                filter(Boolean),
                filter((v: Quote) => !v?.isSaved),
                debounceTime(2500),
                concatMap(({ quote }) =>
                    this.httpService
                        .put<{ version: string }>(
                            `${environment.http.baseUrl}configuration/quotations/${quote.documentId}?revision=${quote.revision}`,
                            quote
                        )
                        .pipe(
                            map((v) => ({ ...v, isSaved: true })),
                            catchError((error) => {
                                this.quoteErrors$.next(error);
                                return of({ version: this.quote$.value?.quote?.version, isSaved: true });
                            })
                        )
                )
            )
            .subscribe(({ version, isSaved }) => {
                const data = this.quote$.getValue();
                if (data) {
                    this.quote$.next({ ...data, quote: { ...data.quote, version }, isSaved });
                }
            });
    }

    public getCurrentQuoteObservable() {
        return this.quote$.pipe(filter((q: Quote, index) => q === undefined || index === 0 || q.isNew || !q.isSaved));
    }

    public getCurrentQuote() {
        return this.quote$.value;
    }

    public fetchQuote({ documentId, latestRevision }: QuoteParams, forceReload = false): Observable<Quote> {
        const quote = this.quote$.getValue();
        if (
            !forceReload &&
            quote?.quote.documentId === documentId &&
            quote?.quote.revision.toString() === latestRevision.toString()
        ) {
            this.quote$.next({
                ...quote,
                isNew: true,
            });
            return of(this.quote$.value);
        }
        this.quote$.next(undefined);

        return this.fetchQuoteFromConfigit(documentId, latestRevision).pipe(tap((q) => this.quoteValidation.check(q)));
    }

    private fetchQuoteFromConfigit(documentId: string, latestRevision: string) {
        return this.httpService
            .get(`${environment.http.baseUrl}configuration/quotations/${documentId}?revision=${latestRevision}`)
            .pipe(
                map(({ quote, properties }: { quote: ConfigitQuote; properties: QuoteAdditionalProperties }) => ({
                    quote,
                    isSaved: true,
                    isNew: true,
                    ...this.getQuoteMetaProperties(quote, properties),
                })),
                tap((result) => this.quote$.next(result))
            );
    }

    private getQuoteMetaProperties(quote: ConfigitQuote, properties?: QuoteAdditionalProperties): QuoteMetaProperties {
        const primary = this.extractQuoteProperty(quote, 'primarySchemes');
        const secondary = this.extractQuoteProperty(quote, 'secondarySchemes');
        const schemePdfs = { primary, secondary };
        const noPriceAdvantage = this.extractQuoteProperty(quote, 'noPriceAdvantage');
        return {
            selectedCustomer: <string>this.extractQuoteProperty(quote, 'companyId'),
            energyLabelHash: this.extractQuoteProperty(quote, 'energyLabelHash'),
            schemePdfs,
            externalApp: this.extractQuoteProperty(quote, 'externalApp'),
            printType: <PrintType>this.extractQuoteProperty(quote, 'printType'),
            salesforceId: this.extractQuoteProperty(quote, 'salesforceId'),
            planningId: this.extractQuoteProperty(quote, 'planningId'),
            planningType: <PlanningType>this.extractQuoteProperty(quote, 'planningType'),
            noPriceAdvantage: typeof noPriceAdvantage === 'string' ? noPriceAdvantage === 'true' : undefined,
            projectIdSF: this.extractQuoteProperty(quote, 'projectIdSF'),
            ...(properties || {}),
        };
    }
    private extractQuoteProperty(quote: ConfigitQuote, propertyKey: string): string | undefined {
        return quote.properties.find((element) => element.key === propertyKey)?.value?.replace(/"/g, '');
    }

    public updateQuoteAssignments(assignments: QuoteAssignment[], material: string) {
        const quote = this.quote$.getValue()?.quote;
        if (quote) {
            const line = quote.lines.find((l) => l.variantCode === material);
            line.assignments = assignments.filter(
                (assignment) =>
                    assignment.isUserAssignment === true ||
                    assignment.isDefault === true ||
                    assignment.variableName === environment.quest.modelSelectionVariable
            );
            this.updateQuote(quote);
        }
    }

    public updateQuoteTitle(newTitle: string) {
        const data = this.quote$.getValue();
        if (!data) {
            return of(undefined);
        }

        this.quote$.next({
            ...data,
            isSaved: false,
            isNew: false,
            quote: { ...data.quote, title: newTitle },
        });
        return this.getQuoteSaveObservable();
    }

    public updateSchemePdfs(schemes: SchemePdf) {
        if (Array.isArray(schemes?.primary)) {
            this.updateQuoteProperty('primarySchemes', schemes.primary.join(';'));
        }
        if (Array.isArray(schemes?.secondary)) {
            this.updateQuoteProperty('secondarySchemes', schemes.secondary.join(';'));
        }
    }

    public updateQuoteProperty(propertyKey: string, newValue: string | boolean | number) {
        const newSaveValue = typeof newValue === 'string' ? `"${newValue}"` : `${newValue}`;
        const quote = this.quote$.getValue();
        if (!quote) {
            return of(undefined);
        }
        let hasChanged = false;
        const updatedQuote = {
            ...quote.quote,
            properties: quote.quote.properties.map((property) => {
                if (property.key === propertyKey && property.value !== newSaveValue) {
                    hasChanged = true;
                    return { ...property, value: newSaveValue };
                }
                return property;
            }),
        };
        if (!hasChanged) {
            return of(undefined);
        }
        this.quote$.next({
            ...quote,
            isSaved: false,
            isNew: false,
            quote: updatedQuote,
            ...this.getQuoteMetaProperties(updatedQuote),
        });
        return this.getQuoteSaveObservable();
    }

    public createAdditionalLine(
        materialInfos: MaterialInfo[],
        type: LineType,
        bomItems: BomItem[],
        assignments: QuoteAssignment[] = []
    ) {
        const quote = this.quote$.getValue()?.quote;
        if (!quote) {
            return of(undefined);
        }

        let line = this.getLineByType(quote, type);
        if (line) {
            // merge assignments into line.assignments
            const replaced = line.assignments.map((a1) => ({
                ...a1,
                ...assignments.find((a2) => a2.variableName === a1.variableName),
            }));
            const newAssignments = assignments.filter(
                (a1) => !line.assignments.some((a2) => a2.variableName === a1.variableName)
            );
            line.assignments = [...replaced, ...newAssignments];
        } else {
            line = this.createLine(quote, 1, materialInfos[0], type);
            line.assignments = assignments;
            // if there were any lines of that type before, remove them
            quote.lines = quote.lines.filter((l) => this.getLineType(l) !== type);
            quote.lines.push(line);
        }
        bomItems.forEach((bomItem) =>
            this.ensureSubLine(
                bomItem.itemId,
                bomItem.componentQuantity,
                materialInfos.find((mi) => mi.name === bomItem.material),
                quote
            )
        );

        this.updateQuote(quote);
        return this.getQuoteSaveObservable();
    }

    private getLines(quote: ConfigitQuote, ...types: LineType[]) {
        return quote.lines.filter((l) => types.includes(this.getLineType(l)));
    }

    private getLineType(line: QuoteLine): LineType {
        return <LineType>line.properties.find((p) => p.key === 'LineType')?.value?.replace(/"/g, '');
    }

    public getPrimaryLine(quote: ConfigitQuote): QuoteLine {
        return this.getLineByType(quote, LineType.primary);
    }

    public getSecondaryLine(quote: ConfigitQuote): QuoteLine {
        return this.getLineByType(quote, LineType.secondary);
    }

    public getTertiaryLine(quote: ConfigitQuote): QuoteLine {
        return this.getLineByType(quote, LineType.tertiary);
    }

    public getLineByType(quote: ConfigitQuote, type: LineType): QuoteLine {
        const lines = this.getLines(quote, type);
        return lines?.length === 1 ? lines[0] : undefined;
    }

    public getExternalProductLines(quote: ConfigitQuote): QuoteLine[] {
        return this.getLines(quote, LineType.product);
    }

    public getProductLines(quote: ConfigitQuote): QuoteLine[] {
        return [...this.getLines(quote, LineType.product), ...this.getSubLines(quote)];
    }

    public hasConfiguration(quote: ConfigitQuote = this.getCurrentQuote()?.quote): boolean {
        return quote && !!this.getPrimaryLine(quote);
    }

    public isProductOptional(quote: ConfigitQuote, material: string) {
        const lines = this.getProductLines(quote);
        const line = lines.find((l) => l.variantCode === material);
        const key = LineProperty.optional;
        let property = line.properties.find((p) => p.key === key);
        if (!property) {
            // create property if it doesn't exist
            property = { key, value: '""' };
            // update line in quote and save it
            const quoteLine = quote.lines.find((l) => l.lineId === line.lineId);
            quoteLine.properties.push(property);
            this.updateQuote(quote);
        }

        return property.value.replace(/"/g, '') === 'Opt';
    }

    public setProductOptional(material: string, optional: boolean) {
        const quote = this.getCurrentQuote().quote;
        // use stringify/parse to create deep copy of lines array
        const lines: QuoteLine[] = JSON.parse(JSON.stringify(quote.lines));
        const line = lines.find((l) => l.variantCode === material);
        const value = optional ? 'Opt' : '';

        const property = line.properties.find((p) => p.key === LineProperty.optional);
        if (property.value.replace(/"/g, '') !== value) {
            property.value = `"${value}"`;
            this.updateQuote({
                ...quote,
                lines,
            });
        }
    }

    public setProductQuantity(material: string, quantity: number) {
        const quote = this.getCurrentQuote().quote;
        const lines = quote.lines.map((l) => Object.assign({}, l));
        const line = lines.find((l) => l.variantCode === material);
        if (line && line.quantity !== quantity) {
            line.quantity = quantity;
            this.updateQuote({
                ...quote,
                lines,
            });
        }
    }

    public removeProductLine(material: string) {
        const quote = this.getCurrentQuote().quote;
        const lines = quote.lines.filter((l) => l.variantCode !== material);
        this.updateQuote({
            ...quote,
            lines,
        });
    }

    public getSubLines(quote: ConfigitQuote, ...materials: string[]): QuoteLine[] {
        const lines = quote.lines.filter((l) => l.isSubBomItem);
        return materials?.length ? lines.filter((l) => materials.includes(l.variantCode)) : lines;
    }

    public ensureSubLine(
        bomItemId: string,
        quantity: number,
        materialInfo: MaterialInfo,
        quote = this.quote$.getValue()?.quote
    ) {
        if (quote) {
            const subLines = this.getSubLines(quote, materialInfo.name);
            let changed: boolean;
            if (subLines.length) {
                // line for material exists, but quantity may have changed
                const existing = subLines[0];
                changed = existing.quantity !== quantity;
                existing.quantity = quantity;
            } else {
                // no line for material exists, create one
                const subline = this.createSubLine(quote, bomItemId, quantity, materialInfo);
                if (subline) {
                    quote.lines.push(subline);
                    changed = true;
                }
            }

            if (changed) {
                this.updateQuote(quote);
            }
        }
    }

    private createSubLine(
        quote: ConfigitQuote,
        bomItemId: string,
        quantity: number,
        materialInfo: MaterialInfo
    ): QuoteLine {
        let line: QuoteLine = undefined;
        const parentId = this.getTertiaryLine(quote)?.lineId || this.getSecondaryLine(quote)?.lineId;
        if (parentId) {
            line = this.createLine(quote, quantity, materialInfo);
            line.bomItemId = bomItemId;
            line.isSubBomItem = true;
            line.parentId = parentId;
        }
        return line;
    }

    private createLine(quote: ConfigitQuote, quantity: number, materialInfo: MaterialInfo, type?: LineType): QuoteLine {
        const id = uuid.v4();
        return {
            language: quote.language,
            id,
            quantity,
            properties: type
                ? [
                      {
                          key: 'LineType',
                          value: `"${type}"`,
                      },
                  ]
                : [],
            materialVariant: {
                language: quote.language,
                code: materialInfo.name,
                image: '',
                description: materialInfo.description,
                assignments: [],
                material: {
                    language: quote.language,
                    properties: materialInfo.properties,
                    name: materialInfo.name,
                    externalId: materialInfo.externalId,
                    productHierarchy: materialInfo.productHierarchy,
                    productHierarchyLevel1: materialInfo.productHierarchyLevel1,
                    productHierarchyLevel2: materialInfo.productHierarchyLevel2,
                    productHierarchyLevel3: materialInfo.productHierarchyLevel3,
                    enableVariantMatching: materialInfo.enableVariantMatching,
                    configurableMaterialName: '',
                    generalItemCategoryGroup: materialInfo.generalItemCategoryGroup,
                    imageUrl: materialInfo.imageUrl,
                    isConfigurable: materialInfo.isConfigurable,
                    longText: materialInfo.longText,
                    description: materialInfo.description,
                    baseUnit: materialInfo.baseUnit,
                    materialDeliveringPlant: materialInfo.materialDeliveringPlant,
                    deliveryUnit: materialInfo.deliveryUnit,
                    assignmentMappings: materialInfo.assignmentMappings,
                    materialSalesAreas: materialInfo.materialSalesAreas,
                },
            },
            variantCode: materialInfo.name,
            baseUnit: materialInfo.baseUnit,
            deliveryUnit: materialInfo.deliveryUnit,
            description: materialInfo.description,
            lineType: 0,
            materialDeliveringPlant: materialInfo.materialDeliveringPlant,
            plant: materialInfo.materialDeliveringPlant,
            createdDate: new Date().toISOString(),
            presetSet: false,
            modelLineType: 0,
            lineId: id,
            assignments: [],
            valid: true,
            bomItemId: '',
        };
    }

    public pruneSubLines(materials: string[], quote: ConfigitQuote) {
        if (quote) {
            const removedSubLines = this.getSubLines(quote).filter((line) => !materials.includes(line.variantCode));
            if (removedSubLines.length) {
                removedSubLines.forEach((line) => {
                    const index = quote.lines.findIndex((l) => l.lineId === line.lineId);
                    quote.lines.splice(index, 1);
                });
                this.updateQuote(quote);
            }
        }
    }

    private updateQuote(quote: ConfigitQuote) {
        const data = this.quote$.getValue();
        if (data) {
            this.quote$.next({ ...data, isSaved: false, isNew: false, quote });
        }
    }

    public getQuoteSaveObservable() {
        return this.quote$.pipe(
            filter((q) => q.isSaved),
            take(1),
            mapTo(undefined)
        );
    }

    public reset() {
        this.quote$.next(undefined);
    }
}

interface QuoteMetaProperties extends QuoteAdditionalProperties {
    selectedCustomer: string;
    externalApp?: string;
    printType?: PrintType;
    schemePdfs: { primary: string; secondary: string };
    energyLabelHash: string;
    salesforceId: string;
    planningId: string;
    planningType: PlanningType;
    noPriceAdvantage: boolean;
    projectIdSF: string;
}

export interface Quote extends QuoteMetaProperties {
    quote: ConfigitQuote;
    isSaved: boolean;
    isNew: boolean;
}

export interface QuoteAdditionalProperties {
    ownerUserName?: string;
    orderType?: string;
}

export interface QuoteAssignment {
    variableName: string;
    valueName: string;
    valueText: string;
    isUserAssignment: boolean;
    isDefault: boolean;
    exclusion: boolean;
}

interface QuoteParams {
    documentId: string;
    latestRevision: string;
}

interface QuoteProperty {
    key: string;
    value: string;
}

export interface ConfigitQuote {
    language: string;
    documentId: string;
    quickRef: string;
    revisionId: string;
    revision: string;
    title: string;
    status: 'New' | 'Quoted';
    salesDocumentNumber: string;
    lines: QuoteLine[];
    properties: QuoteProperty[];
    version: string;
    currency: string;
    salesArea: {
        salesOrganization: string;
        language: string;
    };
    validTo?: string;
}

export interface QuoteLine {
    id: string;
    lineId: string;
    parentId?: string;
    bomItemId: string;
    isSubBomItem?: boolean;
    variantCode: string;
    quantity: number;
    description: {
        serializedTranslations: SerializedTranslation[];
    };
    properties: QuoteProperty[];
    assignments: QuoteAssignment[];
    language: string;
    materialVariant: {
        language: string;
        code: string;
        image: string;
        description: {
            serializedTranslations: SerializedTranslation[];
        };
        assignments: [];
        material: MaterialInfo;
    };

    baseUnit: string;
    deliveryUnit?: string;
    lineType: number;
    materialDeliveringPlant: string;
    plant: string;
    createdDate: string;
    presetSet: boolean;
    modelLineType: number;
    valid: boolean;
}

export interface SerializedTranslation {
    language: string;
    translation: string;
    isDefault: boolean;
}

export interface MaterialInfo {
    language: string;
    properties: QuoteProperty[];
    name: string;
    externalId: string;
    productHierarchy?: string;
    productHierarchyLevel1: string;
    productHierarchyLevel2: string;
    productHierarchyLevel3: string;
    enableVariantMatching: boolean;
    configurableMaterialName: string;
    generalItemCategoryGroup: string;
    imageUrl: string;
    isConfigurable: boolean;
    longText: {
        serializedTranslations: SerializedTranslation[];
    };
    description: {
        serializedTranslations: SerializedTranslation[];
    };
    baseUnit: string;
    materialDeliveringPlant: string;
    deliveryUnit?: string;
    assignmentMappings: [];
    materialSalesAreas: QuoteProperty[];
}

export enum LineType {
    primary = 'PrimaryLine',
    secondary = 'SecondaryLine',
    tertiary = 'TertiaryLine',
    product = 'ProductLine',
}

export enum LineProperty {
    optional = 'OptionalOrAlternative',
}

export type PrintType = 'S' | 'M' | 'L';
